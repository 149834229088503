.closeModal{
    cursor: pointer;
}
.modalTitleRow{
    margin-top: 40px;
}
.scanBelowText {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -10px;
}
.takePaymentValue{
    font-size: 32px;
    font-weight: bolder;
    justify-content: center;
}
.takePaymentPound{
    font-size: 16px;
    font-weight: bolder;
}
.selectedPaymentGrid{
    margin-top: 30px;
}
.testLinkButtonTrue{
    color: #003466;
}
.testLinkButtonFalse{
    display: none;
}
.shareButton {
    --border-radius: 24px;
}