
.borderDiv {
  width: 100%;
  border: 1px solid #dbdada;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.settingsButtons {
  --padding-start: 20px;
  --padding-end: 20px;
  width: auto;
  --border-radius: 10px !important;
}
.logOutButton {
  --background: #ff7100;
  --border-radius: 24px;
  --padding-top: 22px;
  --padding-bottom: 22px;
}
.cancelAccountButton {
  text-align: center;
}
.contactSupport {
  cursor: pointer;
}
.setupButton:disabled {
  background: #888888 !important;
}
