.content {
    height: calc(100% - 65px)
  }
  .menuTitle {
    display: flex;
    justify-content: space-between;
}

.menuTitle > div {
    align-self: center;
}

.itemTitle {
    font-size: .8em;
}

.itemInput {
    margin-top: 8px;
}

.upgradeButton {
    text-transform: none;
}

.questionnnaireFilterDiv {
  display: flex;
  margin-top: -30px;
  margin-bottom: -10px;
  padding-left: 28px;
  
}
.questionnnaireFilterDiv > div {
  align-self: center;
}
.questionnaireInput{
  height: 30px;
  margin-top: -1px;
  margin-bottom: -1px;
  max-width: 500px;
}
.attachDocButton{
  width: 150px;
  --padding-top: 15px;   
 --padding-bottom: 15px;
 --border-radius:12px;
 font-size: 12px;
 
 
}
.cancelDocButton{
  width: 150px;
  --padding-top: 15px;   
 --padding-bottom: 15px;
 font-size: 10px;
 --border-radius:12px;
}

.uploadText{
  margin-top: 10px;
  margin-left: 5px;
  text-align: left;
  color: #17DA36;
}

.completeButton{
  text-align: center;
}

.selectButton{
  margin-left: -30px;
}

.complaintsSelector {
  height: 26px;
  padding-right:20px ;
  font-size: 12px;
  border-radius: 10px;
  background-color: #E5E5E5;
  --placeholder-color: #000000;
  --placeholder-opacity: 1;
  width: 120px;
  min-width: 100px;
}
.tickboxRow{
  margin-top: 25px;
}
.utrRow{
  margin-top: 25px;
}
.regBusAddRow{
  margin-top: 25px;
}
.attachedDocCol{
  font-size: 14px;
}
.titleRow{
  margin-top: 120px;
  margin-bottom: 70px;
  text-align: center;
  color: #003466;
  font-weight: bolder;
  font-size: 24px;
}
.textRow{
  color: #003466;
  text-align: center;
  font-size: 18px;
  margin-bottom: 110px;
}
.textRow2 {
  color: #003466;
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}

.progressBarDiv{
  margin-top: 120px;
}

.text {
  font-weight: bold;
}

.onboardingProgressBar{
  --background: #003466;
  --progress-background: #ff7100;
  

}
.questionnaireTitle{
align-items: center;
font-size: 26px;
font-weight: bold;
color: #ff7100;
margin-bottom: 30px;
}
.businessTickBoxText{
  font-size: 16px;
}
.businessTickBoxTitle {
font-size: 20px;
font-weight: bold;
text-align: center;
color: #003466;
}
.questionnaireTitleRow{
text-align: center;
margin-top: -20px;
}
.spacerDiv{
  margin-top: 30px;

}
.questionnaireCheckBox{
  margin-top: 9px;

}
.checkboxLabel{
  font-size: 12px;
  margin-left: 10px;
}
.formTitle{
  margin-bottom: 10px;
}
.utrTitle{
  margin-bottom: -5px;
  font-size: 14px;
}
.businessNameTitle{
  font-size: 14px;
  font-weight: bold;
}
.natureTitle{
  font-size: 14px;
}
.utrText{
  margin-bottom: -5px;
  font-size: 12px;
}
.natureOfBusinessText{
  font-size: 12px;
  margin-bottom: -1px;
  
}

.title {
  text-align: center;
  color: #003466;
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 35px;
}

.subTitle{
    text-align: center;
  color: #003466;
  font-size: 18px;
  margin-bottom: 17px;
}

.mainText{
    text-align: center;
    color: #003466;
    font-size: 13px;
    margin-bottom: 30px;
}

.subText{
    color: #003466;
    font-size: 12px;
}

.subText2{
  color: #003466;
  font-size: 13px;
  margin-top: 10px;
text-align: center;
}

.progressBarText {
    text-align: center;
}
.progressBarRow{
    margin-top: 25px;
}
.progressBar{
    --background: #003466;
    --progress-background: #ff7100;
}
.submitButtonDiv{
margin-top: 15px;
}
.progressBarText{
    font-size: 18px;
    color: #003466;
}
.paddingDiv{
 padding-bottom: 40px;
}
.buttonAndProgressBar{
  position:fixed;
  bottom: 15%;
  left: 30px;
  right: 30px;
  }

.buttonAndProgressBar2{
    position:fixed;
    bottom: 10%;
    left: 30px;
    right: 30px;
}

.questionnaireScreenImg{
  
  align-items: center;
}
.error-message {
  color: #FF0404;
  text-align: center;
}