.goldSubOnlyBox{
    background-color: #D9D9D9;
    pointer-events: none;    
}


.overlayTextHasGold{
   display: none;

}

.overlayTextNoGold{
    text-align: center;
    margin-bottom: -20px;
    font-weight: bold;
}

.menuTitleNoGold{
    display: flex;
    justify-content: space-between;
    opacity: 0.2;
    color: #003466;
}

.menuTitleNoGold > div {
    align-self: center;
}
.redirectCancelButton{
    --padding-start: 20px;
    --padding-end: 20px;
    width: auto;
    --border-radius: 10px !important;
}
.redirectCancelCol{
    text-align: center;
}