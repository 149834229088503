.uploadMessage{
    font-size: 12px;
    color: #ff7100;
    text-align: center;
}
.logoImage{
    max-height: 300px;
}
.logoCol{
   text-align: center;
}
.uploadSpinner{
    text-align: center;
}