.cancelTitleRow{
    margin-top: 120px;
    margin-bottom: 70px;
    text-align: center;
    color: #003466;
    font-weight: bolder;
    font-size: 24px;
  }
  .cancelTextRow{
    color: #003466;
    text-align: center;
    font-size: 16px;
    margin-bottom: 110px;
  }