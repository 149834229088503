#addSubMerchantButton {
    --border-radius: 24px;
    margin-bottom: 10px;
}

#displayNameTitle {
    font-size: 20px;
    margin-bottom:-12px;
    color: #003466;
}

.displayNameText {
    font-size: 14px;
    margin-bottom: -5px;
    color: #003466;
    text-align: left;
}

#subMerchantEmailTitle {
    font-size: 20px;
    color: #003466;
}

#emailErrorMessage{
    color:#FF0404;
    text-align: center;
    font-size: 14px;
}
#networkErrorMessage {
    color:#FF0404;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
#displayNameErrorMessage{
    color:#FF0404;
    text-align: center;
    font-size: 14px;
}

#subMerchantEmailText {
    color: #003466;
    font-size: 14px;
    margin-top: -15px;
    margin-bottom: -15px;
    text-align: left;
}

.subMerchantProfileText {
    color: #003466;
    font-size: 14px;
    margin-top: -15px;
    margin-bottom: -15px;
}
.buttonAsALink{
    fill: none;
}
.failOrSuccessImage {
    height:120px;
    margin-bottom: 10px;
}

.greyItem
{
    --background:'#f2f2f2'
}

.greyPanelNoPadding {
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-bottom: 16px; /* Keeps bottom margin */
    padding-left: 5px; /* Adds inner spacing instead of shifting the layout */
    padding-right: 5px; /* Adds inner spacing instead of shifting the layout */
    box-sizing: border-box; /* Ensures padding doesn't affect the size */
}

.greyPanelParentContainer {
    justify-content: center; /* Horizontally centers the content */
    align-items: center; /* Vertically centers the content, if necessary */
    padding: 15px; /* Optional: Adds spacing inside the container */
}
