
.officerName {
    font-weight: bold;
    margin-bottom: -8px;
  
    
}

.officerListItem.selected {
    --background: #ff7100;
    color: #ffffff !important;
  }
  
.officerListItem{
    cursor: pointer;
    color: #003466;
    font-weight:600;
    font-size: 14px;
}