p,
.normalText {
  color: #003466;
  font-size: 15px;
}

.center {
  text-align: center;
}

.smallButton {
  width: auto;
}

.width130Button {
  width: 130px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --border-radius: 15px;
}

.width100Button {
  width: 100px;
  margin-top: 22px;
  margin-left: 10px;
  --border-radius: 10px !important;
}

.fullHeight {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.tabPageBottomMargin {
  margin-bottom: 150px;
}

.sectionTitle {
  color: #003466;
  font-size: 20px;
  font-weight: bold;
}

.sectionSubTitle {
  color: #003466;
  font-size: 16px;
  font-weight: bold;
}

.listItem {
  cursor: pointer;
}

.listItem:hover {
  cursor: pointer;
}

.listItemLabel {
  padding-bottom: 20px;
  padding-top: 10px;
  font-weight: bold;
}

.selectableItem {
  cursor: pointer;
  caret-color: transparent;
}

.selectableItem:hover {
  cursor: pointer;
  caret-color: transparent;
}

.successGreen {
  color: #58c800 !important;
}

.input {
  font-size: 12px;
  margin-top: -1px;
  margin-bottom: 10px;
}

.disabledInput {
  font-size: 12px;
  margin-top: -1px;
  margin-bottom: 10px;
  color: #888888;
  opacity: 80%;
}

.subLabel {
  font-size: 12px;
  color: #003466;
  font-weight: 500;
}

.smallSelect {
  height: 25px;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #e5e5e5;
  --placeholder-color: #000000;
  --placeholder-opacity: 1;
  justify-content: left;
}

.Smallselect::part(icon) {
  color: #000000;
}

.select {
  height: 32px;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 12px;
  border-radius: 6px;
  background-color: #e5e5e5;
  --placeholder-color: #000000;
  --placeholder-opacity: 1;
  justify-content: left;
}

.select::part(icon) {
  color: #000000;
}

.smallLogo {
  padding: 0 80px;
  align-items: center;
}

.mainHeader {
  margin: 0;
  width: 100%;
  text-align: center;
  color: #ff7100;
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 25px;
}

.native-input[disabled] {
  opacity: 1 !important;
}

.stagingBanner {
  background-color: #ffa700;
  height: 50px;
  text-align: center;
  align-items: center;
  color: white;
  font-size: large;
  z-index: inherit;
  position: absolute;
  border-radius: 0 0 10px 0px;
  display: flex;
  padding: 0 20px;
}

.no-padding {
  padding: 0;
}

.standardText {
  color: #003466;
  font-size: 15px;
}

.subtitle {
  color: #6e6f70;
  font-size: 10px;
}

.errorText {
  color: red !important;
}

.modal {
  --border-radius: 21px;
  --ion-background-color: #ffffff;
}

.modalGrid {
  margin-top: 30px;
}

.modalHeader {
  font-size: 34px;
  font-weight: bold;
}

.modalText {
  font-size: 14px;
}

.modalStrongText {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
}

.savePresetButton {
  width: 230px;
  height: 30px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.savePreset {
  --ion-color-base: none !important;
  --ion-color-contrast: black !important;
  padding-bottom: 17px;
  padding-right: 17px;
  font-weight: 400;
  font-size: 17px;
}

.smallModal {
  --width: 300px;
  --height: 150px;
  --ion-background-color: #ffffff;
  --backdrop-opacity: 0.4 !important;
  --border-radius: 20px;
}

.datePickerModal {
  text-align: center;
  margin: "auto";
  --height: 400px;
  --width: 400px;
  --ion-background-color: #ffffff;
  --backdrop-opacity: 0.4 !important;
  --border-radius: 20px;
}

.calendar-header::part(calendar-day) {
  --width: 320px;
}

.calendar-month-grid {
  --width: 300px;
}

.bottomColButton:active {
  border: 1px #cacaca solid;
  cursor: pointer;
  background-color: #003466;
  color: #282828;
  color: #fff;
  overflow-x: hidden;
}

.bottomColButton {
  border: 1px #cacaca solid;
  cursor: pointer;
}

.modelContent::part(scroll) {
  overflow: hidden;
}

.border-top {
  border-top: 1px solid #b0b0b0;
}

.border-bottom {
  border-bottom: 1px solid #b0b0b0;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-140 {
  margin-top: 140px;
}

.searchFilterDiv {
  display: flex;
  margin-top: -25px;
  margin-bottom: 10px;
  margin-left: -15px;
  align-self: left;
}

.searchFilterDiv>div {
  align-self: center;
}

.toolbarBackground {
  background-size: 100% 100% !important;
  margin-bottom: 45px;
}

.fullPageNotificationTitle {
  text-align: center;
  color: #003466;
  font-weight: bold;
  font-size: 20px;
}

.fullPageNotificationText {
  text-align: center;
  color: #003466;
}

.standingOrderModal {
  --height: 80%;
}

@media (max-width: 768px) {
  .standingOrderModal {
    --height: 100%;
  }
}

.circularButton {
  --border-radius: 50%;
  width: 35px;
  height: 35px;
}

.circularButtonContent {
  flex-shrink: 0;
  padding-right: 1px;
}

.circularButton:active {
  background-color: #003466;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.circularDeleteButton {
  --background: none;
  color: red;
}

ion-input {
  border: solid 1px #cacaca;
  border-radius: 8px;
}

ion-input input {
  padding: 6px 20px !important;
  color: #a0a0a0;
  font-weight: bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* allows small buttons to display a decent amount of icon */
ion-button {
  --padding-start: 0.5em;
  --padding-end: 0.5em;
  --border-radius: 15px;
  width: 100%;
}

ion-toolbar.titleBar {
  width: 100%;
  box-shadow: 0px 1px 8px #888888;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;

  color: #003466;
  text-align: center;
  font-weight: bold;
}

.greyPanel {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.greyPanel .panelTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.greyPanel h2 {
  color: #003466;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.greyPanel p {
  color: #808080;
}

.greyPanel label {
  color: #003466;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.greyPanel input,
.greyPanel ion-select {
  color: #a0a0a0;
  background-color: #fff !important;
  border-color: #cacaca !important;
  border-width: 1px !important;
  border-radius: 6px !important;
}

.greyPanel .panelTitle {
  color: #003466;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

ion-radio {
  --border-radius: 4px;
  --inner-border-radius: 10px;
  --color: #ddd;
  --color-checked: #003466;
  margin-right: 5px;
  z-index: 1;
}

ion-radio::part(container) {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 25px;
}

.radio-checked::part(container) {
  border-color: #003466;
}

.icon-button {
  --background: #fff; /* Default button background */
  --ion-color: #003466; /* Default icon color */
  transition: transform 0.2s, background-color 0.2s; /* Smooth hover and active effects */
}

.icon-button:hover {
  --background: #e0e0e0; /* Change button background on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.icon-button:active {
  --background: #c0c0c0; /* Change button background on active */
  transform: scale(0.95); /* Slightly shrink on active */
}