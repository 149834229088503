.content {
    height: calc(100% - 65px);
}

.directDebitInputs {
    width: 100%;
    height: 30px;
    margin-bottom: -15px;
    font-size: 13px;    
    color: #757575 !important;
}

.directDebitAddressInput {
    width: 100%;
    height: 30px;
    margin-bottom: -15px;
    font-size: 13px;
}

.directDebitTitleText {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: -10px;
}

.directDebitFormText {
    font-size: 10px;
    margin-bottom: 2px;
    font-weight: bold;
}

.dDCheckbox {
    margin-top: 20px;
}

.dDButton {
    margin-top: 30px;
}

.goCardText {
    font-size: 10px;
    padding-bottom: 80px;
}

.confirmationRow {
    padding-top: 20px;
}
.dDMandatePreTitleRow{
    color: #ff7100 !important;
}

.dDLogo {
    object-fit: contain;
}

.titleDiv {
    box-shadow: none;
    padding-top: 20px;
    padding-bottom: 5px;
}
.buttonRow{
    margin-top: 15px;
}
.buttonDiv{
    width: 120px;
}
.dDMandatePreTitleRow{
    margin-top: 50px;
    margin-bottom: 60px;
    text-align: center;
    color: #003466;
    font-weight: bolder;
    font-size: 24px;
  }
  .directDebitTitle{
    color: #ff7100;
    font-size: 24px;
    margin-bottom: -10px;

  }
  .textRow{
    color: #003466;
    text-align: center;
    font-size: 18px;
    margin-bottom: 110px;
  }
  .textRow2 {
    color: #003466;
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .buttonAndProgressBar{
    position:fixed;
  bottom: 15%;
  left: 30px;
  right: 30px;
}
.goCardText{
    color: #757575;
}
.dDMandatePreScreenImg{
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
  }
  @media (min-width:768px){
    .dDMandatePreScreenImg{
        padding-left: 100px;
        padding-right: 100px;
        align-items: center;
    }
}

.dDMandateScreenImg{
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
  }
  @media (min-width:425px){
    .dDMandateScreenImg{
        padding-left: 100px;
        padding-right: 100px;
        align-items: center;
    }
}
.editableField {
    --color: black !important;
    font-weight: 600;
}