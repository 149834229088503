ion-button{
   text-transform: none;
    --box-shadow: none;
    font-style: normal;
  
}
.submitButton {
    --border-radius: 15px;
}
.submitButtonSquare {
    --border-radius: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}
.text-button {
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline !important;
}