.paymentStatusSuccess{
    color:#17DA36 !important;
    text-transform: lowercase;
}
.paymentStatusSuccess::first-letter{
    color:#17DA36 !important;
    text-transform: uppercase;
}

.paymentStatusPendingOrInProgress{
    color: #ffa700 !important;
    text-transform: lowercase;
}
.paymentStatusPendingOrInProgress::first-letter{
    color: #ffa700 !important;
    text-transform: uppercase;
}

.paymentStatusFailed{
    color: #EA1313 !important;
    text-transform: lowercase;
}
.paymentStatusFailed::first-letter{
    color: #EA1313 !important;
    text-transform: uppercase;
}
.paymentStatus{
    text-transform: lowercase;
    color: #515151;
}
.paymentStatus::first-letter{
    text-transform: uppercase;
}
.selectedPaymentText{
    font-size: 14px;
    
}
.selectedPayment{
border-top: 1px solid #B0B0B0;
}
.paymentItem{
    cursor: pointer;
}
.paymentItem:hover{
    cursor: pointer;
}

.testFilter{
    height: 26px;
    padding-left:10px;
    padding-right:20px;
    font-size: 12px;
    border-radius: 10px;
    background-color: #E5E5E5;
    --placeholder-color: #000000;
    --placeholder-opacity: 1;
    width: 100%;
    min-width: 100px;
    justify-content: left;
}
.testFilter::part(icon){
    color: #000000;
}

.searchButton {
    width: 100px;
    margin-top: 5px;
    margin-left: 10px;
    --border-radius:10px !important
}

ion-input {
    --placeholder-color: #ff7100;
}

.descriptionInput {
    font-size: 12px;
}
.titleDiv{
    width: 100%;
   box-shadow: 0px 1px 8px #888888;
   padding-top: 20px;
   padding-bottom: 10px;
   text-align: center;
}
.paymentHistoryfilterDiv {
    position:relative;
    display: flex;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: -15px;
}

.paymentHistoryfilterDiv > div {
    align-self: center;
}

.paymentHistoryJustMyOwnfilterDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: -25px;
    margin-bottom: -10px;
    margin-left: -15px;
}

.paymentHistoryJustMyOwnfilterDiv > div {
    align-self: center;
}
#submerchantTickBoxLabel{
padding-bottom: 2px;
margin-left: 2px;
color: #003466;
}

#submerchantTickBox {
   
    color: #003466;
    font-size: 14px;
    margin-left: 4px;
}

.paymentID{
    font-size: 8px;
}
.transactionPageTitle {
    color: #003466; 
    text-align: center;
    font-weight: bold;

}
.datePicker{
    --title-color:#003466;
    color: #000000;
    margin: auto;
}
.dateTimeContent{
    text-align: center;
}

.paymentDetailsModal{
   --border-radius: 21px;
   --ion-background-color: #ffffff;
}

.refreshIcon{
color: #4A4A4A
}
.statusOfPaymentTitle{
    margin-bottom: -10px;
}
.paymentHistoryDetailCloseButton
{
    margin-top: 20px;
}
.paymentHistoryBlock{
margin-bottom: 150px;
}
.selectedPaymentPoundSign{
    font-size: 16px;
    font-weight: bold
}
.selectedPaymentValue{
    font-size: 34px;
    font-weight: bold;
}

@media (max-width:400px)
{
    .paymentHistoryfilterDiv {
        display: flex;
        flex-wrap: wrap;
        margin-top: -10px;
        margin-bottom: 10px;
        margin-left: -15px;
        
    }

    .paymentHistoryfilterDiv > div {
        align-self: center;
    }
}