.completiontitleRow{
    text-align: center;
    color: #ff7100;
    font-weight: bolder;
    font-size: 22px;
}
.CompletiontextRow{
    color: #003466;
    text-align: center;
    font-size: 18px;
}

.text {
    font-weight: bold;
}
.textRowEmailNotification{
    color: #003466;
    text-align: center;
    font-size: 16px;
}
.CompletiontextRow48Hours{
    color: #003466;
    text-align: center;
    font-size: 18px;
    margin-bottom: 80px;
}
.titleText{
    color: #ff7100;
    font-weight: bolder;
    text-align: center;
}
.tickImage {
  height: 120px;
}