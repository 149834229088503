ion-text {
    font-weight: 700;
    font-size: 1.5em;
}
.logoMargin {
    margin-top: 10px;
    margin-bottom: 50px;
}

.onboardingTitle {
    color: #ff7100;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}

.onboardingSubText {
    font-size: 13px;
    color: #003466;
}

.onboardingText {
    color: #003466;
    text-align: center;
    font-size: 15px;
    margin-bottom: 30px;
    font-weight: 500;

}

.onboardingSubTitle {
    color: #003466;
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
    font-weight: 500;
}

.onboardingMainText {
    color: #003466;
    font-size: 15px;
}


.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexColumnEvenlySpaced {
    flex: 1;
}

.buttonComplete {
    background-color: rgba(0, 255, 0, 0.3);


}

.onboardingCheckbox {
    margin-top: 13px
}

.dropdownSelector {
border: 1px lightgrey solid;
height: 30px;
border-radius: 5px;
}

.questionnaireErrorTitleRow {
    margin-top: 120px;
    margin-bottom: 70px;
    text-align: center;
    color: #003466;
    font-weight: bolder;
    font-size: 24px;
}

.questionnaireErrorTextRow {
    color: #003466;
    text-align: center;
    font-size: 16px;
    margin-bottom: 110px;
}

.checkboxLabelOnboarding {
    margin-left: 10px;
    margin-top: -10px;
    font-size: 15px;
    font-weight: 400;
   
}

.questionnaireCheckBoxOnboarding {
    margin-bottom: -2px;
    margin-top: 10px;
}

.onboardingMenuItem {
    border-bottom: 0.5px solid #CCCCCC;
    padding-bottom: 10px;
    padding-top: 4px;

}

.onboardingMenuItemTitle {
    font-size: 14px;
}

.menuStatus {
    font-size: 13px;
}

.modalMainText {
    color: #003466;
    font-size: 15px;
}

.modalHeader {
    margin: 0;
    width: 100%;
    text-align: center;
    color: #ff7100;
    font-weight: bolder;
    font-size: 24px;
    margin-bottom: 25px;
}

#fileDisplayButton {
    text-decoration: underline;
    font-size: 13px;
    width: 120px;
    text-overflow: ellipsis;
    color: #003466;
    cursor: pointer;
}

#changeFileButton {
    width: 100px;
    margin-top: -5px;
}

.bottomButtonContainer {
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    max-height: 28rem;
    align-items: flex-end;
}
.bottomButtonRow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
}
.form-button {
    text-align: center;
    background-color: #ff7100;
    color: #fff;
    border-radius: 1.5rem;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
  }
.logoutTextButton {
    --background: clear;
    text-decoration: underline;
    color: #003466;
    font-size: 14px;
}



@media (max-width: 400px) {


    .onboardingMenuItem {
        border-bottom: 0.5px solid #CCCCCC;


    }

    .onboardingMenuItemTitle {
        font-size: 13px;
    }

    .menuStatus {
        font-size: 12px;
    }

    .modalMainText {
        color: #003466;
        font-size: 13px;
    }
    
    .modalHeader {
        margin: 0;
        width: 100%;
        text-align: center;
        color: #ff7100;
        font-weight: bolder;
        font-size: 22px;
        margin-bottom: 25px;
    }

    

}

.top-right-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
}



.helpModalButton {
    height: 50px;
    width: 80px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
    z-index: 1;
}

.closeModalButton {
    position: absolute ;
    bottom: 10;
    top: 100;
    right: 0;
    left: 0;
}

.helpModal {
    --min-height: 340px;
  --height: 340px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.helpModal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }

  .phoneDropdown::part(icon) {
    margin-left: -15px; /* Move the arrow closer */
    margin-right: 15px; /* Adjust if necessary */
  }

  .phoneDropdown::part(label){
    padding-right: -30px;

  }



