#CompHouseInputLabel {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: -1px;
}

.scrollable-content {
  height: 300px;
  overflow-y: scroll;
}


.companyListItem.selected {
  --background: #ff7100;
  color: #ffffff;
}

.companyListItem {
  cursor: pointer;
  color: #003466;
  font-weight: 600;
  font-size: 14px;
}

.companyListItem:hover {
  cursor: pointer;
}

.notRegisteredButton {
  font-size: 11px;
  color: #003466;
  text-decoration: underline;
}

.content {
  height: calc(100% - 65px)
}

.menuTitle {
  display: flex;
  justify-content: space-between;
}

.menuTitle>div {
  align-self: center;
}

.itemTitle {
  font-size: .8em;
}

.itemInput {
  margin-top: 8px;
}

.upgradeButton {
  text-transform: none;
}

.questionnnaireFilterDiv {
  display: flex;
  margin-top: -30px;
  margin-bottom: -10px;

}

.questionnnaireFilterDiv>div {
  align-self: center;
}

.questionnaireInput {
  height: 30px;
  margin-top: -1px;
  margin-bottom: -1px;
  max-width: 500px;
}

.attachDocButton {
  width: 150px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --border-radius: 15px;
  font-size: 10px;

}

.cancelDocButton {
  width: 150px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  font-size: 10px;
  --border-radius: 15px;
}

.uploadText {
  text-align: left;
}

.questionsTitleRow {
  font-size: 24px;
  color: #ff7100;
  text-align: center;
  font-weight: bold;
}

.completeButton {
  text-align: center;
}

.selectButton {
  margin-left: -30px;
}

.complaintsSelector {
  height: 26px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10px;
  border-radius: 10px;
  background-color: #E5E5E5;
  --placeholder-color: #000000;
  --placeholder-opacity: 1;
  width: 250px;
  min-width: 100px;
}

.tickboxRow {
  margin-top: 25px;
}

.utrRow {
  margin-top: 25px;
}

.regBusAddRow {
  margin-top: 25px;
}

.attachedDocCol {
  font-size: 14px;
}

.titleRow {
  margin-top: 120px;
  margin-bottom: 70px;
  text-align: center;
  color: #003466;
  font-weight: bolder;
  font-size: 24px;
}

.textRow {
  color: #003466;
  text-align: center;
  font-size: 18px;
  margin-bottom: 110px;
}

.textRow2 {
  color: #003466;
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}

.progressBarDiv {
  margin-top: 120px;
}

.text {
  font-weight: bold;
}

.onboardingProgressBar {
  --background: #003466;
  --progress-background: #ff7100;


}

.questionnaireTitle {
  align-items: center;
  font-size: 26px;
  font-weight: bold;
}

.questionInput {
  height: 30px;
  margin-top: -1px;
  margin-bottom: -1px;

}

.businessTickBoxTitle {
  font-size: 16px;
}

.questionnaireTitleRow {
  text-align: center;
  margin-top: -20px;
}

.spacerDiv {
  margin-top: 30px;

}

.questionnaireCheckBox {
  margin-top: 9px;
}

.checkboxLabel {
  font-size: 12px
}

.formTitle {
  margin-bottom: 10px;
}

.utrTitle {
  margin-bottom: -5px;
  font-size: 14px;
}

.businessNameTitle {
  font-size: 14px;
}

.natureTitle {
  font-size: 14px;
}

.utrText {
  margin-bottom: -5px;
  font-size: 14px;
}

.natureOfBusinessText {
  font-size: 14px;

}

.title {
  text-align: center;
  color: #003466;
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 35px;
}

.subTitle {
  text-align: center;
  color: #003466;
  font-size: 18px;
  margin-bottom: 17px;
}

.mainText {
  text-align: center;
  color: #003466;
  font-size: 13px;
  margin-bottom: 30px;
}

.subText {
  color: #003466;
  font-size: 12px;
}

.subText2 {
  color: #003466;
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
}

.progressBarText {
  text-align: center;
}

.progressBarRow {
  margin-top: 25px;
}

.progressBar {
  --background: #003466;
  --progress-background: #ff7100;
}

.submitButtonDiv {
  margin-top: 15px;
}

.progressBarText {
  font-size: 18px;
  color: #003466;
}

.paddingDiv {
  padding-bottom: 40px;
}

.buttonAndProgressBar {
  position: fixed;
  bottom: 15%;
  left: 30px;
  right: 30px;
}

.buttonAndProgressBar2 {
  position: fixed;
  bottom: 10%;
  left: 30px;
  right: 30px;
}

.questionnaireScreenImg {

  align-items: center;
}

.errorMessageDiv {
  margin-top: 15px;
  margin-bottom: 40px;
}

.error-message {
  color: #FF0404;
  text-align: center;
  font-size: 14px;
}

.questionsButtonAndProgressBar {
  margin-top: 40px;
  bottom: 15%;
  left: 30px;
  right: 30px;
}

.questionSelectInput {
  height: 30px;
  margin-top: -1px;
  margin-bottom: -1px;
  border-radius: 5px;
  border: 1px solid lightgrey !important;
}



.submit-button {
  width: 100%;
}

.listExtension {
  margin-bottom: 20%;
}
