.mainTitle {
    font-size: 25px;
    text-align: center !important;
    font-weight: 600;
    display: block;
}
.paymentStatus{
    text-transform: lowercase;
    color: #515151;
    margin-top: -5%;
}
.paymentStatus::first-letter{
    text-transform: uppercase;
}
.refreshButton{
    margin-top: 5%;
    min-width: 35px;
    position:absolute;
}
.paymentStatusBox{
    background-color: #ECECEC;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%; 
    height: 15%;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 0.6);
    margin-bottom: 5%;
}
.transactionText{
    font-family: 'Poppins', sans-serif;
    color: #b8b8b8;
    font-size: 14px;
    
}
.paymentStatusTitle{
    margin-top: 5%;
   font-size: 16px;
    
}
.statusOfPaymentRow{
    margin-top: -5%;
}
.transactionCounter{
    color: #ff7100 !important;
}
.transactionCountText{
    text-align: center;
    color: #003466;
    font-size: 16px;
}
.takePaymentContent{
    --background: transparent !important;
}
.takePaymentButton{
    --border-radius: 24px;
    height: 40px;
}
.descriptionItem{
    --border-stlye:none !important;
}
#additionalInfo {
    font-size:14px !important ;
    font-weight: 500;
}
.inputLabel {
    font-size: 14px;
    color: #003466;
    font-weight: 500;
}

.paymentInfoInputs{
    margin-top: -1px;
    margin-bottom: -1px;
}

.paymentDescriptionInputs{
    margin-top: -1px;
    margin-bottom: -1px;
}
.inputHelperMessage{
    text-decoration: underline;
    margin-top: -10px;
}