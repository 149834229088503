.accountList{
    text-align: center;
    font-size: 16px;
    color: #003466;
}
.accountListTitle{
    text-align: center;
    font-size: 18px;
    color: #003466;
}

.bankButtonAndProgressBar{
    margin-top: 50px;
  bottom: 15%;
  left: 30px;
  right: 30px;
    }
.chooseYourBankTitleRow{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #ff7100;
    font-weight: bolder;
    font-size: 24px;
}
.bankAccountScreenImg{
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
}
.documentList {
    list-style-position: inside;
    color: #003466;
    text-align: left;
    font-size: 15px;
    margin-bottom: 30px;
    padding-left: 1px;
    font-weight: bold;
  }
.bankTextRow{
    color: #003466;
    text-align: center;
    font-size: 15px;
    margin-bottom: 50px;
  }
@media (min-width:768px){
    .bankAccountScreenImg{
        padding-left: 100px;
        padding-right: 100px;
        align-items: center;
    }
}
.accountSelectButton{
    height: 30px;
}